.App {
  text-align: center;
}

.navbar-items{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.unfold-navbar-collapsible{
  display: none;
}
@media (min-width: 1000px) {
  .ul-nav{
    display: flex;
    flex-direction: row;  
    margin: 0;
  }
}
@media (max-width: 1000px) {
  .fond-montagne-container{
    height: 40vh;
    position: relative;
    z-index: 0;
  }
  .fond-montagne{
    z-index: 0;
    max-height: 50vh;
    min-height: 40vh;
    min-width: 100vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    filter: blur(0.7px) saturate(1.3);
    top: 0;
  }
  .div-navbar{
    display: flex;
  }
  .li-nav{
    border:solid white;
    border-width: 1px;
  }
  .navigation-bar{
    position: absolute;
    top:40vh ;
    right: 0;
    bottom: 0;
    height: 6vh;
    flex-direction: column; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .unfold-navbar-collapsible{
    display: flex;
    height: 100;
    right: 0;
    position: absolute;
    z-index: 9999;
  }
  .ul-nav{
    flex-direction: column;  
    margin: 0;
    position: absolute;
    width: 100%;
    top: 0vw;
    transform: scaleY(0);
    background-color: #6cc7ad;
    height: 80vh;
  }
  .navbar-items{
    padding-bottom: 1vw;
    width: 100%;
  }
  .button-nav{
    position: relative;
    z-index: 999;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .titre-logo {
    font-size: 3rem;
    font-weight: 100;
    font-family:'Gill Sans', Calibri, sans-serif ;}
    .soustitre {
      font-size: 1.5rem;
      font-weight: 100;
      
      font-family:'Gill Sans', Calibri, sans-serif ;
      margin: auto;
    }
    .logo-container {
      position: relative;
      z-index: 9999;
      display: flex;
      height: 100%;
     margin: auto;
    }
    .logo-artesophro{
      margin: auto;
      margin-right: 1rem;
      height: 12rem;
    }
    .titre-container{
      margin: auto;
      margin-left: 1rem;
    }

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.footer-container {
  height: auto;
  display: flex;
  margin-top: 8vw;
  flex-direction: row;
  background-color: #65B39D;
}

.sommaire {
  padding-top: 2vw;
  padding-bottom: 2vw;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-family:sans-serif;
}

.title-sommaire {
  font-size: calc(1.25vw + 10px);
  font-family:sans-serif;
  font-weight: 300;
  font-stretch: semi-expanded;
}

.sommaire-ul {
  list-style: none;
  padding-inline-start: 0;
}

.coordonnées {
  display: flex;
  flex-direction: column;
  padding-top: 2vw;
  padding-bottom: 2vw;
  margin-left: auto;
  margin-right: auto;
}
.sommaire-li{
  color: black;
  text-decoration: none;
  font-weight: 200;
  padding-top: 0.2rem;
  font-size: 1.2rem;
}
.sommaire-li:hover{
  color: white;
}

.sommaire-container {
  margin: auto;
}
.coordonnees-title{
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: 300;
  font-stretch: semi-expanded;
}
  .coordonnees-values-container{
    display: flex;
    flex-direction: column;
  }
  .coordonnees-title-container{
    margin-bottom: 12px;
    color: white;
  }
  .coordonnees-values{
    text-decoration: none;
    color: black;
    padding-top: 0.5rem;
    font-family: sans-serif;
    font-size: 1.2rem;
  }
  .coordonnees-values:hover{
    color: white;
  }
  .annexe-container{
    padding-top: 2vw;
    margin-left: auto;
    margin-right: auto;
  }
  .annexe-title{
    color: white;
    font-family: sans-serif;
    font-size: 2rem;
    margin-bottom: 12px;
  }
  .annexe-value{
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-family: sans-serif;
  }