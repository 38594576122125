.seances{
    margin: 5vw;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 7vw ;
    position: relative;
    z-index: 99;
    padding: 5vw;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;}
@media (max-width: 1000px) {
    .seances{
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .me-5{
        margin-right: 0 !important;
    }
}