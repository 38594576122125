
.tarifs ,.rdv{
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 2vw;
}
.end-tarif{
    font-size: 1.2rem;
}

.contenu{
    margin: 5vw;
}
.liste-info{
    font-size: 1.2rem;
}
.infos-title{
    font-weight: 600;
    color:  #65B39D;
    margin-bottom: 2rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.text-infos{
    font-size: 1.2rem;
}
.coordinates-container{
    display: flex;
    height: 4vw;
    margin-top: 3vw;
}
.telephone-container{
    width: 50%;
    background-color: red;
}
.mail-container{
    width: 20%;
    display: flex;
    margin-right: 2rem;
}
.button-perso{
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
    scale: 0.7;
}
.mail-ref{
    width: 3vw;
    text-decoration: none;
    height: 100%;

    margin: auto;
}
li::marker{
    color:#65B39D
}
i{
    margin-right:5px;
}
.button-coordinates{
    color: black;
    margin: auto;
    background-color: aqua;

}