.theme-artesophro{
    color: #65B39D;
}
.back-theme-artesophro{
    background-color: #65B39D !important;
}
.one-px{
    height: 1px;
}
.p-li{
    margin-bottom: 0.3rem;
}