
.ref-article{
    text-decoration: none;
}
.article-container{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: .5s;
}
.article-description{
    margin-top: 2rem;
    color: rgb(92, 92, 92);
    margin-bottom: auto;
}
.article-infos{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
.article-title{
    font-size: 2rem;
    font-weight: 500;
    color: #65B39D ;
}
.article-container:hover{
box-shadow: 0px 6px 13px 3px rgb(0 0 0 / 13%);
    transform: translate(0px,-6px);}
.article-vignette{
    width: auto;
    height: 10rem;
    margin: auto;
}
.article-vignette-container{
    display: flex;
}
.article-creation-date{
    color: black ;
    

}
@media (max-width: 800px) {
    .article-vignette-container{
        margin-bottom: 1rem;
    }
    .article-infos{
        margin:0 ;
    }
    .article-vignette{
        height: auto;
        width: 100%;
    }
    .article-container{
        padding: 2rem;
        display: flex;
        flex-direction: column;
    }
    .article-list-container{
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
}