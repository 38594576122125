.texte-gauche-img{
    display: flex;
    flex-direction: column;
align-items: center;
width: 40%;
margin-left: 6vw;
margin: auto;
text-align: justify;

}
.title-texte-qui{
    width: 100%;
}
.title-qui{
    font-size: 1.8rem;
    font-weight: 600;
    color:  #65B39D;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.texte-gauche-img-p{
    line-height: 1.5;
    font-size: 1.2rem
}
.image{
    display: flex;
    padding-bottom: 3.5vw;
    padding-top: 3.5vw;
}
.img-droite{
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: calc(25vw + 50px);
    border-radius: 10%;
}

.texte-droite-img{
    display: flex;
align-items: center;
width: 50vw;

}
.texte-droite-img-p{
    line-height: 1.5;
    font-size: 1.2rem;
}
.image-gauche{
    display: flex;
}
.img-gauche{
    margin: auto;box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: calc(25vw + 50px);
    border-radius: 10%;
}

@media (max-width: 800px) {
    .droite{
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .gauche{
        flex-direction: column-reverse;
        margin-bottom: 2rem;
    }
    .texte-gauche-img , .texte-droite-img{
        width: 100%;
    }
    .img-droite, .img-gauche{
        width: 100%;
    }
    .page-container{
        margin-left: 2rem;
        margin-right: 2rem;
    }
}