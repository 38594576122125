body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.logo-artesophro {
  height: calc(18vw + 40px);
}

.titre-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  color: white;
  font-family: 'Century Gothic, sans serif';
  font-weight: 400;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
}
body{
  font-family: "Gelasio";
}
.titre-logo {
  font-size: 4rem;
  font-weight: 100;
  font-family:'Gill Sans', Calibri, sans-serif ;}

.ul-nav {
  margin: 0;
  padding: 0;
  width: auto;
  margin: auto;
  height: 100%;
  display: flex;
}

.navbar-items {
  width: 100%;
  font-size: calc(10px + 0.8vw);
  font-weight: 500;
  font-family: 'Open sans';
  color: white;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: 0.2s;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}

.soustitre {
  font-size: 2.5rem;
  font-weight: 100;
  
  font-family:'Gill Sans', Calibri, sans-serif ;
}



.navbar-items:hover {
  background-color: white;
  color: black;
}

.li-nav {
  text-align: center;
  height: 100%;
  font-size: 1vw;
  display: flex;
  flex-direction: row;

  text-align: center;
  list-style: none;
}

.navbar-container {
  background-color: black;
  height: 22vw;
}

.navigation-bar {
  background-color: rgba(104, 182, 160, 0.8);
  position:sticky;
  width: 100%;
  bottom: 0;
  height: 4vw;
  display: flex;
  z-index: 999;
  min-height: 3vh;
  justify-content: center;
  bottom: 0;
  transform: translate( 0,-99% );
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  
}



.fond-montagne-container {
  overflow: hidden;
  height: 22vw;
}

@media (min-width:1000px){
  .logo-container {
    position: absolute;
    z-index: 999;
    display: flex;
    left: 50%;
    height: calc(15.5vw + 40px);
    transform: translate(-58%);
  }
  .fond-montagne {
    width: 100%;
    filter: blur(0.7px) saturate(1.3);
    transform: translate(0%, -37%);
  }
}

