.titre-gauche{
    font-weight: 370;
    margin-left: 15vw;
    margin-top: 4vh;
    font-size: 1.8rem;
    font-style:oblique;


}
.texte-gauche{
    text-align:left;
    height: 25vw;
    margin-left: 6vw;
    height: fit-content;
    font-size: 1.2rem;
    width: 80%;
    line-height: 1.5;
    line-height: 1.5;
    margin-top: 4vh;
    text-align: justify;
}

.le-fonctionnement{
    margin-top: 4vh;
    border-radius: 1rem;
    padding-top: 2vh;
    background-color: #65B39D;
    color: white;
    padding-bottom: 3vw;
}
.titre-droite{
    font-weight: 370;
    margin-right: 10vw;
    font-size: 1.8rem;
    font-style:oblique;
text-align: right;


}
.texte-droite{
    height: fit-content;
    margin-right: calc(15px + 1vw);
    margin-left: auto; 
    font-size:1.2rem;
    width: 85%;
    line-height: 1.5;
    margin-top: 4vh;
    text-align: justify;
}
.li-sophro{
    margin-bottom: calc(15px + 0.2vw) ;
}