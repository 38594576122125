.article-list-container {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    margin-right: 5rem;
    text-decoration: none;
}

.intro-actu {
    margin-bottom: 3rem;
    font-size: 1.3rem;
}