
.text{
    text-align: justify;
    font-size: calc(.4vw + 12px);
    line-height: 1.5;
}
.page-container{
    margin-right: 5rem;
    margin-left: 5rem;
}

.logo-chambre{
    display: flex;
 
}
.logo-img{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    margin: auto;
    width: calc(10vw + 90px);
}



.photo-id-praticienne-container{
    display: flex;

}
.text-with-photo{
    width: 90%;
}
@media (max-width: 800px){.photo-id-praticienne{
    width: 75vw;
    margin: auto;
    margin-top: 5vw;
    margin-bottom: 5vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}}

@media (min-width: 800px){
    .titre{
        margin-bottom: 6vh;
        margin-top: 4vh;
        font-weight: 370;
        font-size: 3.5rem;
        font-style:oblique;
    }
    .photo-id-praticienne{
    width: 35vw;
    margin: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}}
.text-with-photo-container{
    display: flex;
    
}
@media (min-width: 800px){.text-with-logo{
    display: flex;
    margin: auto;
    margin-top: 6vw;
    margin-bottom: 4vw;
}
.praticienne-with-photo{
    display: flex;
    flex-direction: row;
}
.text-with-photo-container{
    width: 60%;
}
}

@media (max-width: 800px){
    .text{
        font-size: 1.2rem;
        line-height: 1.5;
        margin-top: 5vh;
    }
    .titre{
        margin-top: 4vh;
        font-weight: 370;
        font-size: 3.5rem;
        font-style:oblique;
    }
    .praticienne-with-photo{
        flex-direction: column;
    }
    .text-with-photo{
        width: 100%;
}
    .text-with-photo-container{
        width: 100%;
}}

@media (min-width: 800px){.text-container{
    width: 60%;
    margin-left: auto;
}}

@media (max-width: 800px){.logo-chambre{
    display :none;
}}